
var loginRequired = function($q, $location, $auth, $rootScope, $localStorage, $state) {
    var deferred = $q.defer();
    var host = $location.host();

    if ($auth.isAuthenticated()) {
        setTimeout(function () {
            if( !$localStorage.company.plan_active && location.pathname != '/cart'){
                $rootScope.$broadcast('auth:login-success');
                $location.path('/cart');
            }
        }, 200);
        deferred.resolve();
    } else {
        $rootScope.$broadcast('auth:logout-success');
        $location.path('/auth');
    }

    return deferred.promise;
}

serialize = function(obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

var app = angular.module('larabase', [
    'ui.router',
    'ui.bootstrap',
    'ncy-angular-breadcrumb',
    'angulartics',
    'angulartics.google.tagmanager',
    'ngAnimate',
    'slugifier',
    'ngSanitize',
    'idf.br-filters',
    'underscore',
    'ngImgCrop',
    'ngFileUpload',
    'highcharts',
    'angularPromiseButtons',
    'luegg.directives',
    'ckeditor',
    'ngTextareaEnter',
    '720kb.tooltips',
    // 'socket',
    'angucomplete-alt',
    'btford.socket-io',
    'ui.calendar',
    'angular.img',
    'credit-cards',
    'ui.utils.masks',
    'mgo-angular-wizard',
    'offer',
    'shopping_cart',
    'cashier',
    // 'scategory',

    // Basic modules
    'home',
    'profile',
    'report',
    'auth',
    'config',
    'roles',
    'users',
    'user_role',
    'templates',
    'cart',
    'managerial',
    'transaction',
    'company',
    'system_alert',
    'digital_account',
    'movement',
    'purchase',

    // Specific modules
    'department',
    'accounts_plan',
    'account_entry',
    'financial_entry',
    'financial_balance',
    'financial_group',
    'client',
    'client_status',
    'client_document',
    'client_address',
    'client_contract',
    'service',
    'category',
    'provider',
    'countryState',
    'city',
    'process',
    'protocol',
    'tax_regime',
    // 'chat',
    // 'chat_status',
    'user_notification',
    'tax_regime_status',
    'contract_type',
    'document_type',
    'anamnesis_type',
    'receipt_type',
    'bulletin',
    'schedule',
    'invoice',
    'billing',
    'client_room',
    'gavruk.card',
    'color.picker',
    'chart.js',
    'service_category_group',
    'class_group',
    'check_in_class_group',
    'modality',
    'ecommerce_component',
    'company_ecommerce_component',
    'base_data'
]);

app.value('qtd', 1);
app.value('find', false);
app.value('origin', null);
app.value('showInModal', false);
app.value('id', null);
app.value('company_id', false);
app.value('dates', false);
app.value('type', null);

app.value('$uibModalInstance', false);

app.config([

    '$authProvider',
    '$stateProvider',
    '$urlRouterProvider',
    '$locationProvider',
    '$httpProvider',
    '$analyticsProvider',
    function(
        $authProvider,
        $stateProvider,
        $urlRouterProvider,
        $locationProvider,
        $httpProvider,
        $analyticsProvider
    ) {

        /**
         * Auth Provider
         */
        $authProvider.storageType = 'localStorage';
        $authProvider.loginUrl = server + '/login';
        $authProvider.signupUrl = server + '/signup';
        $authProvider.facebook({
            clientId: '1179976082014644'
        });

        $httpProvider.interceptors.push('HttpInterceptor');

        $locationProvider.html5Mode(true);
        $urlRouterProvider.otherwise("/");

        $stateProvider.state('notFound', {
            url: "/404",
            templateUrl: server + '/views/errors/404',
            controller: 'list',
            ncyBreadcrumb: {
                parent: 'home',
                label: 'Página não encontrada'
            }
        });

    }
]);

app.config(['ngToastProvider', function(ngToast) {
    ngToast.configure({
        horizontalPosition: 'center',
        maxNumber: 3,
        combineDuplications : true
    });
}]);

app.factory('HttpInterceptor', function($q, $location, $localStorage) {
    return {
        request : function(config) {
            var apiPattern = /\/api\//;
            config.params = config.params || {};

            if(config.page && !config.params.page)
                config.params.page = config.page;

            if(config.count && !config.params.count)
                config.params.count = config.count;

            if($localStorage.company && apiPattern.test(config.url))
                config.params.i = $localStorage.company.id;

            return config || $q.when(config);
        }
    };
});

app.directive('enter',function(){
	return function(scope,element,attrs){
		element.bind("keydown keypress",function(event){
			if(event.which===13){
				event.preventDefault();
        var form=$(this).parents('form:eq(0),body');
				var fields=$(this).parents('form:eq(0),body').find('input, textarea, select');
				var index=fields.index(this);
				if(index> -1&&(index+1)<fields.length)
					fields.eq(index+1).focus();
			}
		});
	};
});

app.directive('ngHtml', function() {
	return function(scope, element, attrs) {
		scope.$watch(attrs.ngHtml, function(value) {
			element[0].innerHTML = value;
		});
	}
});


app.filter('reverse', function() {
    return function(items) {
        return items.slice().reverse();
    };
});

app.filter('getDate', function(){
    return function(input){
        if( input )
            return new moment(input).toISOString();
    }
});
app.filter('getAge', function(){
    return function(input){
        if( input ){
            var age = moment().diff(input, 'years');
            return age;
        }
    }
});

app.filter('fistName', function(){
    return function(input){
        if(input && input != ''){
            var slices = input.split(' ');
            input = slices[0];
        }
        return input;
    }
});

app.filter('fistAndLastName', function(){
    return function(input){
        if(input != undefined && input != ''){
            var slices = input.split(' ');
            var firstName = slices[0];
            var lastName = slices.slice(-1);
            if(firstName != lastName){
                input = firstName+' '+lastName;
            }
        }
        return input;
    }
});

app.run(['$state', '$rootScope', '$localStorage', '$analytics', '$user_notification', '$company', '$sale', '$uibModal', '$http', 'ngToast', '$uibModal', '$uibModalInstance',
function($state, $rootScope, $localStorage, $analytics, $user_notification, $company, $sale, $uibModal, $http, ngToast, $uibModal, $uibModalInstance) {
    $rootScope.$state = $state;

    $rootScope.show = false;
    $rootScope.str = '';


    $rootScope.showTerms = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'showTerms.modal.html',
            size: 'lg',
            scope: $rootScope,
            controller: ['$state', '$scope', '$uibModalInstance', '$sce',
            function($state, $scope, $uibModalInstance, $sce) {

                $scope.terms   = $sce.trustAsHtml(companyTerms);

                $scope.cancel = function() {
                    $uibModalInstance.close();
                };

            }]
        });
    }

    $rootScope.showSale = function(){
        if(!$localStorage.cart)
            return;

        $sale.updateEcommerce($localStorage.cart, $localStorage.cart.hashid).then(function(response){
            $localStorage.cart = response;
            $rootScope.closeBag();
            $state.go('checkout', {id: $localStorage.cart.hashid});
        }, function(error){

        });
    }

    $rootScope.showCart = function(){
        if(!$localStorage.cart)
            return;

        $sale.updateEcommerce($localStorage.cart, $localStorage.cart.hashid).then(function(response){
            $localStorage.cart = response;
        }, function(error){

        });
    }

    $rootScope.closeBag = function(){
        $('.shopping__cart').removeClass('shopping__cart__on');
        $('.body__overlay').removeClass('is-visible');
    }

    $rootScope.openCategory = function(category){
        // $rootScope.filterEcommerce = [];
        // $rootScope.filterEcommerce.push(category);

        $state.go('offer_list');
    }

    $rootScope.openCategoryIndex = function(item){
        $rootScope.filterEcommerce = [];
        $rootScope.filterEcommerce.push(item);

        $state.go('offer_list');
    }

    $rootScope.searchingEcommerce = function(find = null){
        $state.go('offer_list', {find: $rootScope.str});
    }

    $rootScope.setShow = function(){
        $rootScope.show = !$rootScope.show;
    }

    if( $localStorage.company ){
        $rootScope.company = $localStorage.company;
        $company.get({id:$localStorage.company.id}).$promise.then(function(response){
            $localStorage.company = response;
            var last_transaction = (response.last_transaction[0] != undefined) ? response.last_transaction[0] : undefined;
            $rootScope.$broadcast('account_payment', last_transaction);
        });
    }

    if( $localStorage.user )
        $rootScope.companies = $localStorage.user.companies;

    $rootScope.$on('$stateChangeSuccess', function() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        var userId = $localStorage.user ? $localStorage.user.id : false;
        if (userId) {
            $analytics.setUsername(userId);
        }
    });

    $rootScope.selectCompany = function(company){
        $localStorage.company = company;
        location.reload();
    }

    $rootScope.$on('cart:item_added', function(e) {
        var total = 0;
        _.each($localStorage.cart.items, function(item){
            total = parseFloat(total) + (item.value ? parseFloat(item.value) : 0);
        });

        $localStorage.cart.total = total;

        if(!company.show_menu){
            $sale.updateEcommerce($localStorage.cart, $localStorage.cart.hashid).then(function(response){
                $localStorage.cart = response;
                $rootScope.closeBag();
                $state.go('checkout', {id: $localStorage.cart.hashid});
            }, function(error){

            });
        }
    });

    $rootScope.removeCartItem = function(index){

        if(!$localStorage.cart.hashid){
            $localStorage.cart.items.splice(index, 1);
            ngToast.danger({
                content: 'Item removido',
                dismissOnTimeout: true,
                timeout: 2000
            });
            $rootScope.closeBag();
        } else {
            $sale.removeItem($localStorage.cart.items[index].id).then(function(response){
                $localStorage.cart.items.splice(index, 1);
                ngToast.danger({
                    content: 'Item removido',
                    dismissOnTimeout: true,
                    timeout: 2000
                });

                $sale.get({id: $localStorage.cart.hashid}).$promise.then(function(response){
                    $localStorage.cart = response;
                });
                $rootScope.closeBag();
                window.location.reload()
            }, function(error){
                ngToast.danger({
                    content: 'Erro na operação',
                    dismissOnTimeout: true,
                    timeout: 2000
                });
            });
        }
    }

    $rootScope.dropdownTranslation = {
        buttonDefaultText: 'Selecione',
        checkAll: 'Todas',
        uncheckAll: 'Nenhuma',
        selectionCount: 'selecionada(s)',
        selectionOf: '/',
        searchPlaceholder: 'Pesquisar',
        dynamicButtonTextSuffix: 'selecionada(s)'
    };

    $rootScope.dropdownConfig = {
        buttonClasses: 'btn btn-default btn-block',
        scrollable: true,
        scrollableHeight: '200px',
        enableSearch: true,
        styleActive: true,
        showCheckAll: false,
        showUncheckAll: true,
        displayProp: 'name',
        idProp: 'id',
        selectionLimit: 1,
        closeOnSelect: true,
        smartButtonMaxItems: 1,
        keyboardControls: true
    };

    $rootScope.diffCreated = function(timeStampCreated){

        var timeStampCurrent = new Date(moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
        var timeStampCreated = new Date(timeStampCreated);

        var diff = timeStampCurrent.getTime() - timeStampCreated.getTime();
        var days = Math.floor(diff / (60 * 60 * 24 * 1000));
        var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
        var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
        var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));

        if(days > 1){
           return (moment(timeStampCreated).locale('pt-br').format("DD MMMM YYYY")+" às "+
                  moment(timeStampCreated).locale('pt-br').format("HH:mm"));
        }
        else{
            if(days < 2 && hours < 1 && minutes < 1){
                return ("há alguns segundos.");
            }
            else if(days < 2 && hours < 1){
                if(minutes <= 1){
                    return ("há "+ minutes +" minuto.");
                }else{
                    return ("há "+ minutes +" minutos.");
                }
            }
            else if(days < 1){
                if(hours > 1){
                    return ("Há "+ hours + " horas.");
                }
                else {
                    return ("há "+ hours + " hora.");
                }
            }
            else if(days < 2){
                return ("Ontem às "+ moment(timeStampCreated).locale('pt-br').format("HH:mm"));
            }
        }
    }

    $rootScope.status_options = [{
        id: 1,
        label: 'Ativo'
    }, {
        id: 0,
        label: 'Inativo'
    }];

    $rootScope.openDate = function(widget) {
        widget.opened = true;
    };

    $rootScope.setFocusID = function(id){
        setTimeout(function () {
            document.getElementById(id).select();
        }, 100);
    }

    $rootScope.load = function(){
        // $user_notification.getNotificationsUnread().then(function(response) {
        //     $rootScope.notificationsUnread = response.data;
        // }, function(error) {
        //     if (error.status == 404) {
        //         $state.go('notFound');
        //     }
        // });
    }

    setInterval(function(){ $rootScope.load(); }, 90000);

    $rootScope.daysOfMonth = [
        '1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16',
        '17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'
    ];

    $rootScope.months = [
        {id: 1, description: 'Janeiro'},
        {id: 2, description: 'Fevereiro'},
        {id: 3, description: 'Março'},
        {id: 4, description: 'Abril'},
        {id: 5, description: 'Maio'},
        {id: 6, description: 'Junho'},
        {id: 7, description: 'Julho'},
        {id: 8, description: 'Agosto'},
        {id: 9, description: 'Setembro'},
        {id: 10, description: 'Outubro'},
        {id: 11, description: 'Novembro'},
        {id: 12, description: 'Dezembro'}
    ];

    $rootScope.numbersIntallments = ['1.00','2.00','3.00','4.00','5.00','6.00','7.00','8.00','9.00','10.00','11.00','12.00','13.00','14.00','15.00'];
    $rootScope.arrayInstallments = [1,2,3,4,5,6,7,8,9,10,11,12];

    $rootScope.saveNewsletter = function(name, email){
        var data = {
            name : name,
            email : email
        };

        $http.post(api+'client/store_newsletter', data).then(function(response){
            // Função sucesso
            ngToast.success({
                content: 'Email incluido na lista!',
                dismissOnTimeout: true,
                timeout: 2000
            });
            $rootScope.newsletter.name = '';
            $rootScope.newsletter.email = '';
        }, function(error){
            ngToast.danger({
                content: 'Erro ao adiconar email',
                dismissOnTimeout: true,
                timeout: 2000
            });
        });
    }

    $rootScope.sumFloat = function(num1, num2){
        return parseFloat(num1) + parseFloat(num2);
    }

    $rootScope.objToUrlParams = function(obj){
        var str = "";
        for (var key in obj) {
            if (str != "")
                str += "&";

            str += key + "=" + encodeURIComponent(obj[key]);
        }

        return str;
    }

    $rootScope.print = function(data){
        var w = window.open('about:blank', '_blank');
        w.document.write(data);
        w.moveTo(0, 0);
        setTimeout(function () {
            w.focus();
            w.print();
            w.close();
        }, 100);
    }

    $rootScope.chartOptions = {
        legend: {
            display: true,
            position: 'right'
        }
    };

    $rootScope.selectService = function(data, showModal){
        if(showModal){
            var modalInstance = $uibModal.open({
                animation       : true,
                templateUrl     : server+"/views/m/service/show",
                size            : 'lg',
                controller      : 'service.show',
                backdrop        : 'static',
                resolve         : {
                    showInModal : true,
                    company_id  : $localStorage.company.id,
                    id          : data.id
                }
            });
            $rootScope.showInModal = showModal;
            modalInstance.result.then(function(data) {
                console.log(data);
            });
        } else {
            $state.go('service_show',{id: data.id});
        }

    }
}]);
